<template>
    <div class="top_Header">
        <header class="headers">
            <img src="../../../public/image/1.png" alt="">
            <img class="imgSrc" src="../../../public/image/yunshuju_logo.png" alt="">
        </header>

        <div class="for_44">
            <p>参数错误</p>
            <p>请重新扫码</p>
        </div>

        <div class="bgphoto">
            <img src="../../assets/bg.png" alt="">
        </div>

        <div class="exit">
            <!-- <button>退出</button> -->
        </div>


        <div class="footers">
            <img src="../../assets/中国汽车售后服务质量监测大数据平台 提供.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {

        },
        props: {

        },
        data() {
            return {
               
            };
        },
        methods: {


        },
        computed: {

        },
        watch: {

        },
        created() {
        document.body.removeChild(document.getElementById("Loading"));
        },

        mounted() {},
    };
</script>

<style scoped lang="scss">
    .top_Header {
        width: 100%;
        height: 100%;

        
        .headers {
            display: flex;
            margin: 0px 27px;
            margin-top: 30px;
            align-items: center;

            .imgSrc {
                margin-left: 9px;
            }
        }
        .for_44{
            text-align: center;
            font-size: 30px;
            color: #126de7;
            font-weight: 600;
            margin-top: 100px;
            p{
                padding: 5px 0px;
            }
        }
       

        .content {
            width: 100%;
            height: 100%;
            margin: 0px 27px 95px;
        }

       

        .exit {
            margin-top: 330px;
        }

       

        .foters {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            margin-top: 10px;

            p {
                width: 268px;
                height: 44px;
                font-size: 16px;
                font-family: AlibabaPuHuiTiR;
                color: #FFFFFF;
                line-height: 22px;
                text-align: center;
            }
        }


        .footers {
            margin-top: 35px;

            img {
                display: block;
                margin: 0px auto;
            }
        }

    }
</style>